import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: '/api' });


// const axiosInstance = axios.create({ baseURL: 'http://16.171.54.114:8000/' });

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => error
);

export default axiosInstance;